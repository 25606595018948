import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router';
import $, {trim} from 'jquery';
import ENDPOINT from '../../ultils/EndPoint';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function CategoryEditAndStore() {
    // set const
    const {id} = useParams();
    const [nameCategory, setNameCategory] = useState('');
    const [nameParent, setNameParent] = useState([]);
    const [parentName, setParentName] = useState(0);
    const [ordinalNumbers, setOrdinalNumbers] = useState('');
    const [childParent, setChildParent] = useState(1);
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const dispatch = useDispatch();

    // set msg
    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // others
    const [disable, setDisable]= useState(false);


    // handle change
    const handleNameCategory = (e) => {
        setNameCategory((e.target.value).replace(/\s\s+/g, ' '))
    }
    const handleRemoveNameCategory = () => {
        validationMsg.nameCategory = '';
        setMsg('')
        $('.name_category').removeClass('danger_border');
    }

    const handleNameParent = (e) => {
        setParentName(e.target.value)
    }

    const handleOrdinalNumbers = (e) => {
        setOrdinalNumbers(e.target.value)
    }
    const handleRemoveNumberCategory = () => {
        validationMsg.ordinalNumbers = '';
        $('.ordinal_category').removeClass('danger_border')
    }

    const handleChildParent = (e) => {
        setChildParent(e.target.value)
    }

    const handleMetaTitle = (e) => {
        setMetaTitle(e.target.value)
    }
    const handleRemoveTitle = () => {
        validationMsg.metaTitle = ''
        $('.titile_category').removeClass('danger_border')
    }

    const handleMetaKey = (e) => {
        setMetaKey(e.target.value)
    }
    const handleRemoveKey = () => {
        validationMsg.metaKey = ''
        $('.key_category').removeClass('danger_border')
    }

    const handleMetaDescription = (e) => {
        setMetaDescription(e.target.value)
    }
    const handleRemoveDesc = () => {
        validationMsg.metaDescription = ''
        $('.desc_category').removeClass('danger_border')
    }

    // get option Father
    useEffect(()=>{
        axios.get(`${ENDPOINT.OPTION_CATALOG}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setNameParent(response.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
    },[dispatch]);

    // set for edit
    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.CATALOG}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setNameCategory(response.data.data.name)
                    setParentName(response.data.data.parent_id)
                    setMetaTitle(response.data.data.meta_title)
                    setMetaKey(response.data.data.meta_key)
                    setMetaDescription(response.data.data.meta_desc)
                    setOrdinalNumbers(response.data.data.order_no)
                    if(response.data.data.have_child === true){
                        setChildParent(1)
                    }else {
                        setChildParent(0)
                    }
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id, dispatch]);

    // validate before submit
    const validateAll = () => {
        const msg = {};

        if(nameCategory.length > 50) {
            msg.nameCategory = 'Thông tin Tên danh mục tối đa 50 kí tự'
            $('.name_category').addClass('danger_border');
        }else if( nameCategory.charAt(0) === '' ){
            msg.nameCategory = 'Vui lòng nhập tên danh mục tin tức'
            $('.name_category').addClass('danger_border');
        }else if(nameCategory.slice(0, 2) === ' '){
            msg.nameCategory = 'Vui lòng nhập tên danh mục tin tức'
            $('.name_category').addClass('danger_border');
        }

        if(!id) {
            if(ordinalNumbers.length <= 0) {
                msg.ordinalNumbers = 'Vui lòng nhập số thứ tự'
                $('.ordinal_category').addClass('danger_border')
            }else if(ordinalNumbers.length < 1
                || ordinalNumbers > 10000
                || !positive.test(ordinalNumbers)
                // || (ordinalNumbers.charAt(0) === '0' && ordinalNumbers.charAt(1) === '0')
                || ordinalNumbers[0] === '0'){
                msg.ordinalNumbers = 'Số thứ tự là số nguyên dương lớn hơn bằng 1 và nhỏ hơn 10000';
                $('.ordinal_category').addClass('danger_border')
            }
        }else {
            if(ordinalNumbers.length <= 0) {
                msg.ordinalNumbers = 'Vui lòng nhập số thứ tự'
                $('.ordinal_category').addClass('danger_border')
            }else if(ordinalNumbers.length < 1
                || ordinalNumbers > 10000
                || !positive.test(ordinalNumbers) ){
                msg.ordinalNumbers = 'Số thứ tự là số nguyên dương lớn hơn bằng 1 và nhỏ hơn 10000';
                $('.ordinal_category').addClass('danger_border')
            }else if(ordinalNumbers) {
                if(ordinalNumbers[0] === '0') {
                    msg.ordinalNumbers = 'Số thứ tự là số nguyên dương lớn hơn bằng 1 và nhỏ hơn 10000';
                    $('.ordinal_category').addClass('danger_border')
                }
            }
        }


        if(metaTitle.length <= 0) {
            msg.metaTitle = 'Không được để trống thẻ tiêu đề'
            $('.titile_category').addClass('danger_border')
        }

        if(metaKey.length <= 0) {
            msg.metaKey = 'Không được để trống thẻ từ khoá'
            $('.key_category').addClass('danger_border')
        }

        if(metaDescription.length <= 0) {
            msg.metaDescription = 'Không được để trống thẻ mô tả'
            $('.desc_category').addClass('danger_border')
        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    // params for update client
    const updateAdmin = new FormData();
    updateAdmin.append('name', trim(nameCategory));
    updateAdmin.append('meta_key', metaKey);
    updateAdmin.append('meta_title', metaTitle);
    updateAdmin.append('meta_desc', metaDescription);
    updateAdmin.append('parent_id', parentName);
    updateAdmin.append('have_child', childParent)
    updateAdmin.append('order_no', ordinalNumbers)

    // submit when evething fine
    const createCategory = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.CATALOG}`, updateAdmin,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm danh mục tin tức thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/catalog-news'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Thông tin Tên danh mục đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }

    // params for update client
    const updateAdminInfo = new FormData();
    updateAdminInfo.append('name', trim(nameCategory));
    updateAdminInfo.append('parent_id', parentName);
    updateAdminInfo.append('meta_key', metaKey);
    updateAdminInfo.append('meta_title', metaTitle);
    updateAdminInfo.append('meta_desc', metaDescription);
    updateAdminInfo.append('have_child', childParent);
    updateAdminInfo.append('order_no', ordinalNumbers);


    // submit when evething fine
    const updateCategory = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.CATALOG}/${id}`, updateAdminInfo,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa danh mục tin tức thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/catalog-news'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Thông tin Tên danh mục đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }


    // only type number
    useEffect(() => {
        $('input[name="inputForNumber"]').keypress(
            function(event)
            {
                if (event.keyCode === 46 || event.keyCode === 8)
                {

                }
                else
                {
                    if (event.keyCode < 48 || event.keyCode > 57 )
                    {
                        event.preventDefault();
                    }
                }
            }
        );

        $( "#custom_select" ).change(function () {
            if($("option:selected", this ).val()=="0"){
                $("#children_have").show();
            }else{
                $("#children_have").hide();
            }
        });
    })




    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa danh mục tin tức</h1> : <h1 className="customer-title mb-3">Thêm mới danh mục tin tức</h1>}
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên danh mục <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control name_category" value={nameCategory} onKeyDown={handleRemoveNameCategory} onChange={handleNameCategory} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px', 'marginBottom':'0px'}} className="text-danger msg_name">{validationMsg.nameCategory}</p>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p>
            {/* ====================================================================== */}
            <Form.Label className="col-2 pl-0 pr-0 ">Danh mục cha <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group mb-3">
                <select disabled={id ? true : false} name="" id="custom_select" className="form-control custom_select" style={{'cursor': id ? 'not-allowed' : 'pointer'}} onChange={handleNameParent}>
                    <option value="0">Danh mục gốc</option>
                    {nameParent && nameParent.map((parent, index) => <option key={index} selected={parentName === parent.id ? true : false} value={parent.id}>{parent.name}</option>)}
                </select>
            </div>
            {/* ====================================================================== */}
                <div>
                    <Form.Label className="col-2 pl-0 pr-0 ">Số thứ tự <span style={{'color':'red'}}>*</span></Form.Label>
                    <div className="input-group">
                        <input type="text" value={ordinalNumbers} name="inputForNumber" onKeyDown={handleRemoveNumberCategory} onChange={handleOrdinalNumbers} className="form-control ordinal_category" aria-label="Username" aria-describedby="basic-addon1"/>
                    </div>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.ordinalNumbers}</p>
                </div>

            {/* ===================================================================== */}
                <div id="children_have">
                    <Form.Label className="col-2 pl-0 pr-0 ">Có danh mục con</Form.Label>
                    <div className="input-group mb-3">
                            <select disabled={id ? true : false} onChange={handleChildParent} className="form-control custom_select"  id="inputGroupSelect04" style={{'cursor': id ? 'not-allowed' : 'pointer'}}>
                                <option selected={id && childParent === 1 ? true : false} value="1">Có</option>
                                <option selected={id && childParent === 0 ? true : false} value="0">Không</option>
                            </select>
                    </div>
                </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ tiêu đề <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control titile_category" value={metaTitle} onKeyDown={handleRemoveTitle} onChange={handleMetaTitle} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ từ khoá <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control key_category" value={metaKey} onKeyDown={handleRemoveKey} onChange={handleMetaKey} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaKey}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Thẻ mô tả <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control desc_category" value={metaDescription} onKeyDown={handleRemoveDesc} onChange={handleMetaDescription} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaDescription}</p>
            {/* <p>Trạng thái</p>
            <div className="d-flex">
                <div className="custom-control custom-radio mr-3">
                    <input type="radio" id="customRadio1" name="customRadio" className="custom-control-input"/>
                    <label className="custom-control-label" style={{'paddingTop':'3px', 'cursor':'pointer'}} htmlFor="customRadio1">Hiển thị</label>
                </div>
                <div className="custom-control custom-radio">
                    <input type="radio" id="customRadio2" name="customRadio" className="custom-control-input"/>
                    <label className="custom-control-label" style={{'paddingTop':'3px', 'cursor':'pointer'}} htmlFor="customRadio2">Ẩn</label>
                </div>
            </div> */}
            <div >
                <Link to="/main/catalog-news"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {id ? <button type="button" disabled={disable} onClick={updateCategory} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createCategory} className="btn btn-success mt-5">Lưu</button>}
            </div>
        </div>
    )
}

export default CategoryEditAndStore


