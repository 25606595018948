import React, {useState, useEffect} from 'react';
import $, {trim} from 'jquery';
import ENDPOINT from '../../ultils/EndPoint';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import {useParams} from "react-router";
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import EditorToolbar, {formats, modules} from "../toolbar/QuillToolbar";
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function NewsEditAndCreate() {
    const {id} = useParams();
    // catalog_id selected
    const [nameParent, setNameParent] = useState('');
    const [parentName, setParentName] = useState([]);

    const [nameNews, setnameNews] = useState('');
    const dispatch = useDispatch();
    const [metaTitle, setMetaTitle] = useState('');
    const [metaKey, setMetaKey] = useState('');
    const [Description, setDesciption] = useState(''); // thẻ mô tả //
    const [metaDescription, setMetaDescription] = useState(''); // mô tả //
    const [metaContent, setMetaContent] = useState(''); // nội dung //


    const [metaStatus, setStatus] = useState(1);
    const [imageURL, setImageURL] = useState('');
    const [iconServer, setIconServer] = useState('');
    const [slugName, setSlugName] = useState('');


    const [validationMsg, setValidationMsg] = useState({});
    const [msg, setMsg] = useState('');


    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

    // test positive integer
    const positive =  /^\+?(0|[1-9]\d*)$/

    // others
    const [disable, setDisable]= useState(false);

    const handleNameNews = (e) => {
        setnameNews((e.target.value).replace(/\s\s+/g, ' '))
    }

    const handleRemoveNameNews = () => {
        validationMsg.nameNews = '';
        setMsg('')
        $('.name_category').removeClass('danger_border');
    }



    const handleMetaTitle = (e) => {
        setMetaTitle((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveTitle = () => {
        validationMsg.metaTitle = ''
        $('.titile_category').removeClass('danger_border')
    }

    const handleChildParent = (e) => {
        setStatus(e.target.value)
    }

    const RemoveChildparent = () => {
        $('.statusSelected').removeClass('product_warning')
        validationMsg.metaStatus = ''
    }

    const handleCatalog = (e) => {
        setNameParent(e.target.value)
    }
    const RemoveMessCategory = () => {
        $('.categorySelected').removeClass('product_warning')
        validationMsg.nameParent = ''
    }

    const handleMetaKey = (e) => {
        setMetaKey((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveKey = () => {
        validationMsg.metaKey = ''
        $('.key_category').removeClass('danger_border')
    }
    const handleMetaDescription = (event) => {
        setMetaContent(event)
    }
    const handleRemoveDesc = () => {
        validationMsg.metaDescription = ''
        $('.desc_category').removeClass('danger_border')
    }

    const handleMetaDesc = (e) => {
        setDesciption(e.target.value)
    }

    const handleRemoveMetaDes= () => {
        validationMsg.Description = ''
        $('.detail_content').removeClass('danger_border')
    }

    const handleContent = (e) => {
        setMetaDescription((e.target.value).replace(/\s\s+/g, ''))
    }
    const handleRemoveContent = () => {
        validationMsg.metaDescription = ''
        $('.content_category').removeClass('danger_border')
    }


    // call api for edit
    useEffect(()=>{
        if(id) {
            axios.get(`${ENDPOINT.NEWS}/${id}`, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                }
            })
            .then(function (response) {
                if(response.status === 200) {
                    setnameNews(response.data.data.name)
                    setImageURL(response.data.data.image)
                    setIconServer(response.data.data.image)
                    setMetaTitle(response.data.data.meta_title)
                    setMetaKey(response.data.data.meta_key)
                    setMetaDescription(response.data.data.meta_desc) // mô tả //
                    setDesciption(response.data.data.description) // thẻ mô tả //
                    setMetaContent(response.data.data.content) // nội dung //
                    setStatus(response.data.data.status)
                    setNameParent(response.data.data.new_catalog_relation[0].id);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        }
    },[id, dispatch]);

    useEffect(()=>{
        axios.get(`${ENDPOINT.CATALOG}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
        .then(function (response) {
            if(response.status === 200) {
                setParentName(response.data.data);
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch(logoutSuccess(true))
            }
        }) 
    },[dispatch]);

    // validate before submit

    const validateAll = () => {
        const msg = {};

        if(nameNews.length > 255) {
            msg.nameNews = 'Thông tin tên tin tức không được quá 255 kí tự'
            $('.name_category').addClass('danger_border');
        }else if( nameNews.charAt(0) === '' ){
            msg.nameNews = 'Vui lòng nhập tên tin tức'
            $('.name_category').addClass('danger_border');
        }else if(nameNews.slice(0, 2) === ' '){
            msg.nameNews = 'Vui lòng nhập tên tin tức'
            $('.name_category').addClass('danger_border');
        }
        if(imageURL === ''){
            msg.imageURL = "Vui lòng tải lên ảnh feature"
        }

        if(metaTitle.length <= 0) {
            msg.metaTitle = 'Không được để trống thẻ tiêu đề'
            $('.titile_category').addClass('danger_border')
        }

        if(metaKey.length <= 0) {
            msg.metaKey = 'Không được để trống thẻ từ khoá'
            $('.key_category').addClass('danger_border')
        }

        if(metaDescription.length <= 0) {
            msg.metaDescription = 'Không được để trống thẻ mô tả'
            $('.desc_category').addClass('danger_border')
        }

        if( metaContent.charAt(0) === '' ){
            msg.metaContent = 'Không được để trống nội dung'
            $('.content_category').addClass('danger_border');
        }else if(metaContent.slice(0, 2) === ' '){
            msg.metaContent = 'Không được để trống nội dung'
            $('.content_category').addClass('danger_border');
        }

        if(Description.length <= 0) {
            msg.Description = 'Không được để trống thẻ mô tả chi tiết'
            $('.detail_content').addClass('danger_border')
        }

        if(nameParent.length === 0) {
            msg.nameParent = 'Vui lòng chọn danh mục'
            $('.categorySelected').addClass('product_warning')

        }
        if(metaStatus.value === 0) {
            msg.metaStatus = 'Vui lòng chọn trạng thái'
            $('.statusSelected').addClass('product_warning')

        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }

    const onFileChange = (e) => {
        setImageURL(e.target.files[0])

        var reader = new FileReader();

        reader.onload = function(e) {
            $('#imagePreview').attr('src', e.target.result);
            $('#imagePreview').hide();
            $('#imagePreview').fadeIn(650);
            $('.image_icon').addClass('image_icon1');
            $('.icon_remove_image').addClass('icon_remove_image1');
        }
        if (e.target.files[0].size > 5e6) {
            alert("Ảnh không thể tải lên được. Kích thước tập tin vượt quá 5MB");
            return false;
        }else {
            reader.readAsDataURL(e.target.files[0])
        }
    }

    const handleRemoveUpload = () => {
        $("#imageUpload").val("");
        setImageURL(iconServer);
        $("#imagePreview").attr("src", iconServer);
        $('.image_icon').removeClass('image_icon1');
        $('.icon_remove_image').removeClass('icon_remove_image1');
    }


    const updateAdminInfo = new FormData();
    updateAdminInfo.append('name', trim(nameNews));
    updateAdminInfo.append('meta_key', metaKey);
    updateAdminInfo.append('meta_title', metaTitle);
    updateAdminInfo.append('meta_desc', metaDescription);
    updateAdminInfo.append('description', Description);
    updateAdminInfo.append('content', metaContent);
    updateAdminInfo.append('status', metaStatus);
    updateAdminInfo.append('catalog_news_id', nameParent);
    if(iconServer === imageURL){

    }else {
        updateAdminInfo.append('image', imageURL);
    }
    // updateAdminInfo.append('have_child', childParent);
    // updateAdminInfo.append('order_no', ordinalNumbers);


    // submit when evething fine
    const updateNews = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.put(`${ENDPOINT.NEWS}/${id}`, updateAdminInfo,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Chỉnh sửa tin tức thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/news'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        setMsg('Tên tin tức đã tồn tại')
                        $('.name_category').addClass('danger_border');
                    }
                }
            )

    }

    const createNews = new FormData();
    createNews.append('name', trim(nameNews));
    createNews.append('meta_key', metaKey);
    createNews.append('meta_title', metaTitle);
    createNews.append('meta_desc', metaDescription);
    createNews.append('description', Description);
    createNews.append('content', metaContent);
    createNews.append('image', imageURL);
    createNews.append('status', metaStatus);
    createNews.append('catalog_news_id', nameParent);

    // submit when evething fine
    const createNewsBtn = () => {
        const isValidate = validateAll()
        if(!isValidate) return

        setDisable(true)
        setTimeout(() => {
            setDisable(false)
        }, 2000);

        return axios.post(`${ENDPOINT.NEWS}`, createNews,  {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    toast.success('Thêm mới tin tức thành công !')
                    setTimeout(() => {
                        window.location.href = '/main/news'
                    }, 2000);
                }
            })
            .catch(error => {
                    if(error.request.status === 422) {
                        // setMsg(errors.name)
                        $('.name_category').addClass('danger_border');
                    }
                }
            )
    }


    return (
        <div className="pl-4 pr-4 mt-3 edit_store_admin">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    {id ? <h1 className="customer-title mb-3">Chỉnh sửa tin tức</h1> : <h1 className="customer-title mb-3">Thêm mới tin tức</h1>}
                </div>
            </div>
            <Form.Label className="col-2 pl-0 pr-0 ">Tên tin tức<span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control name_category" value={nameNews} onKeyDown={handleRemoveNameNews} onChange={handleNameNews} aria-label="Username" aria-describedby="basic-addon1" placeholder="Tên tin tức"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameNews}</p>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{msg}</p>
            <div className="d-flex justify-content-between">
                <div className="grid w-50">
                    <select
                        onChange={handleCatalog}
                        value={nameParent}
                        className=" mr-1 form-control custom_select categorySelected"
                        id="inputGroupSelect04"
                        onMouseDown={RemoveMessCategory}
                        >
                        <option value=""></option>
                        {parentName &&
                            parentName.map(function (parent,i) {
                                return (
                                    <>
                                        <option value={parent.id}>{parent.name}</option>
                                        { parent.child !== null && parent.child.length > 0 ?
                                            parent.child.map(function(childParent, index){
                                                    return <option value={childParent.id} key={index}>&nbsp;&nbsp;&nbsp;&nbsp;{childParent.name}</option>
                                                })
                                        : ''}
                                    </>
                                )
                            })}
                    </select>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.nameParent}</p>
                </div>
                {/* ================================================= */}
                <div className="grid w-50">
                    <select
                        onChange={handleChildParent}
                        onMouseDown={RemoveChildparent}
                        value={metaStatus}
                        className="ml-1 form-control custom_select statusSelected"
                        id="inputGroupSelect04"
                    >
                        <option  value="1">Xuất bản</option>
                        <option  value="2">Không xuất bản</option>
                    </select>
                    <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaStatus}</p>
                </div>
            </div>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Mô tả<span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control content_category" value={Description} onKeyDown={handleRemoveMetaDes} onChange={handleMetaDesc} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaContent}</p>
            <Form.Label className="col-2 pl-0 pr-0 ">Nội dung<span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <EditorToolbar/>
                <ReactQuill
                    modules={modules}
                    formats={formats}
                    onChange={handleMetaDescription}
                    value={metaContent  || ''}
                />
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaDescription}</p>

            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ tiêu đề <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control titile_category" value={metaTitle} onKeyDown={handleRemoveTitle} onChange={handleMetaTitle} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaTitle}</p>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ từ khoá <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control key_category" value={metaKey} onKeyDown={handleRemoveKey} onChange={handleMetaKey} aria-label="Username" aria-describedby="basic-addon1"/>
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaKey}</p>
            <Form.Label className="mt-2 col-2 pl-0 pr-0 ">Thẻ mô tả <span style={{'color':'red'}}>*</span></Form.Label>
            <div className="input-group">
                <input type="text" className="form-control detail_content " value={metaDescription} aria-label="Username" aria-describedby="basic-addon1"  onKeyDown={handleRemoveContent} onChange={handleContent} />
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.metaDescription}</p>
            <div className="input-group mt-3">
                <button htmlFor="imageUpload" type="button" className="btn btn-add">Hình ảnh<input className="input_uploadImage" onChange={onFileChange} type='file' id="imageUpload" accept=".png, .jpg, .jpeg" /></button>
                {imageURL ?
                    <div className="w-100 mt-2">
                        <div id="close_btn_wapper">
                            <img className="image_icon" style={{'marginRight':'30px','width':'32px', 'height':'32px'}} alt="" id="imagePreview" src={imageURL}></img><i style={{'cursor':'pointer', 'fontSize': '0px'}} id="btn_close" onClick={handleRemoveUpload} className="mdi mdi-delete icon_remove_image"></i>
                        </div>
                    </div>
                    : ''}
            </div>
            <p style={{'fontSize': '12px', 'marginTop':'0px'}} className="text-danger msg_name">{validationMsg.imageURL}</p>
            <div >
                <Link to="/main/news"><button type="button" className="btn btn-secondary mt-5 mr-4">Quay lại</button></Link>
                {id ? <button type="button" disabled={disable} onClick={updateNews} className="btn btn-success mt-5">Cập nhật</button> : <button type="button" disabled={disable} onClick={createNewsBtn} className="btn btn-success mt-5">Lưu</button>}
            </div>
        </div>
    )
}


export default NewsEditAndCreate
