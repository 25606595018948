import React, {useEffect, useState,useCallback} from 'react'
import {Link} from "react-router-dom";
import $ from "jquery";
import ENDPOINT from "../../ultils/EndPoint";
import axios from "axios";
import {Button, Modal} from "react-bootstrap";
import {toast, ToastContainer} from "react-toastify";
import {Pagination} from "antd";
import Form from 'react-bootstrap/Form';
import {useDispatch} from 'react-redux';
import {logoutSuccess} from '../../actions/Auth';

function News() {
    const [listNews, setlistNews] = useState([]);
    const [keySearch, setKeySearch] = useState('');
    const [idUserCurr, setIdUserCurr] = useState('');
    const [nameUserCurr, setNameUserCurr] = useState('');
    const [filterUser, setfilterUser] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [customersPerPage] = useState(10);
    const [currentPage, setcurrentPage] = useState(1);
    const [total, setTotal] = useState();
    const [startDate, setStartDate]= useState('');
    const [endDate, setEndDate]= useState('');
    const [handleSelect, setHandleSelect] = useState('');
    const [handleSelectStatus, setHandleSelectStatus] = useState('');
    const [validationMsg, setValidationMsg] = useState({});
    const dispatch = useDispatch();

    const [statusPublic, setStatusPublic] = useState('');


    const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');


    // set constans admin token
    const admin_token = localStorage.getItem('User-Admin')
    const config = {
        headers: { Authorization: `Bearer ${admin_token}` }
    };

    const debounce = (func) => {
        let timer;
        return function (...args){
            const context = this;
            if(timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, args)
            }, 1000)
        }
    }

    const handleSelectedStatus = (e) => {
        setStatusPublic(e.target.value)
    }

    const handleInputSearch = (e) => {
        setKeySearch(removeExtraSpace(e.target.value));
        setcurrentPage(1)
    }

    // Handle filter by date
    const handleSelected = (event) => {
        setHandleSelect(event.target.value);
        setcurrentPage(1)
    }


    const handleChangeStartDate = (e) => {
        setStartDate(e.target.value);
        setcurrentPage(1)
    }

    const handleChangeEndDate = (e) => {
        setEndDate(e.target.value);
        setcurrentPage(1)
    }

    const optimisedChange = debounce(handleInputSearch,1000)
    const optimisedChange1 = debounce(handleChangeStartDate, 2000)

    const handleCheckDate = (start, end) => {
        if(start <= end) {
            return true
        }else if(start !== '' && end === '') {
            return true
        }else if(start > end) {
            return false
        }
    }

    const validateAllConfig = useCallback( () => {
        const msg = {}

        if(handleCheckDate(startDate, endDate) !== true){
            msg.startDate = 'Đến ngày phải lớn hơn từ ngày'
        }else {
            msg.startDate = ''
        }

        setValidationMsg(msg)
        if(Object.keys(msg).length > 0){
            return false
        }else{
            return true
        }
    }, [startDate, endDate])

    function handleReset(){
        setcurrentPage(1)
        setKeySearch("")
        setStartDate("")
        setEndDate("")
        setStatusPublic("")
        setHandleSelect("");
        $("#searchCategory").val("")
        $("#checkfilter").val("")
        $("input[type=date]").val("")
        $("#checkfilterPublic").val("")
    }


    useEffect(() => {
        // const token = localStorage.getItem('User-Admin')
        const getTerm = async () => {
            try {
                let query = '';
                const data = {
                    per_page: customersPerPage,
                    keyword: keySearch,
                    from_date: startDate,
                    to_date: endDate,
                    catalog_news_id: handleSelect,
                    status: statusPublic,
                    page: currentPage
                };
                Object.keys(data).map((key, index) =>
                    query += (index === 0 ? '?' : "&") + key + '=' + data[key]
                )
                const  res = await axios.get(`${ENDPOINT.NEWS + query}`, {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
                    }
                })
                setlistNews(res.data.data.records)
                setTotal(res.data.data.total_record)
            } catch(error) {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }
        }
        getTerm();

    }, [currentPage, customersPerPage, keySearch,endDate, statusPublic,startDate,handleSelect,validateAllConfig, dispatch])

    useEffect(()=>{
        let unmounted = false;
        axios.get(`${ENDPOINT.CATALOG}`, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('User-Admin')
            }
        })
            .then(function (response) {
                if(response.status === 200) {
                    setfilterUser(response.data.data);
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch(logoutSuccess(true))
                }
            }) 
        return () => { unmounted = true };
    },[dispatch]);

    const handleDelete = (id) => {
        axios.delete(`${ENDPOINT.NEWS}/${id}`, config)
            .then(function (response) {
                toast.success('Xóa tin tức thành công !')
                setTimeout(() => {
                    window.location.href = '/main/news'
                }, 2000);
            })
            .catch(error => {
                    alert(error.response.data.error)
                }
            )
    }

    // search debounce
    $('#searchCategory').on('keyup keypress', function(e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });
    return (
        <div className="pl-4 pr-4 mt-3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="operatelog">
                <div className="d-flex justify-content-between align-self-center">
                    <h1 className="customer-title mb-0">Danh sách tin tức</h1>
                    <Link to ="/main/news/create"><button type="button" className="btn btn-add"> + Thêm</button></Link>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <div className="w-100">
                    <form className="position-relative pt-0 pb-0 app-search d-none d-lg-block">
                        <div className="d-flex">
                            <Form.Group className="mr-4 w-25">
                                <input onChange={optimisedChange} defaultValue={keySearch}  id="searchCategory" className="form-control" type="search" placeholder="Tìm kiếm" aria-label="Search" style={{ background: "#fff", border: '1px solid #ced4da'}}/>
                                <span className="bx bx-search-alt"></span>
                            </Form.Group>
                            <Form.Group className="mr-4 w-25">
                                <select name="" id="checkfilter" className="form-control custom_select" onChange={handleSelected}>
                                    <option value="">Danh mục</option>
                                    {filterUser &&
                                    filterUser.map(function (filter,i) {
                                        return (
                                            <>
                                                <option value={filter.id}>{filter.name}</option>
                                                { filter.child !== null && filter.child.length > 0 ?
                                                    filter.child.map(function(childParent, index){
                                                        return <option value={childParent.id} key={index}>&nbsp;&nbsp;&nbsp;&nbsp;{childParent.name}</option>
                                                    })
                                                    : ''}
                                            </>
                                        )
                                    })}
                                </select>
                            </Form.Group>
                            <Form.Group className="w-25">
                                <select name="" id="checkfilterPublic" className="form-control custom_select" onChange={handleSelectedStatus}>
                                    <option value="">Trạng thái</option>
                                    <option value="1">Xuất bản</option>
                                    <option value="2">Không xuất bản</option>
                                </select>
                            </Form.Group>
                        </div>
                        <div className="d-flex mt-2">
                            <Form.Group className="d-flex mr-5">
                                <Form.Label className="align-items-center mb-0 pl-0 pr-0 col-3 align-self-center">
                                    Từ ngày
                                </Form.Label>
                                <Form.Control type="date" id="start_date" onChange={optimisedChange1}></Form.Control>
                            </Form.Group>
                            <div className="grid">
                                <Form.Group className="d-flex">
                                    <Form.Label className=" align-items-center mb-0 pl-0 pr-0  col-3 align-self-center">
                                        Đến ngày
                                    </Form.Label>
                                    <Form.Control type="date" id="end_date" onChange={handleChangeEndDate}></Form.Control>                               
                                     <p className="ml-5 pl-4 text-danger" style={{ "position": "absolute"}}>{validationMsg.startDate}</p>
                                </Form.Group>
                            </div>
                            <button type='button' className="btn btn-info btn-reload" onClick={handleReset} style={{marginLeft: "50px"}}>
                                <i className="fas fa-sync-alt"></i>
                            </button>
                        </div>


                    </form>

                </div>
            </div>
            <div>
                <table className="table project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                        <th>ID</th>
                        <th>Tiêu đề</th>
                        <th>Danh mục</th>
                        <th>Ngày tạo</th>
                        <th>Hình ảnh</th>
                        <th>Trạng thái</th>
                        <th>Hành động</th>
                    </thead>
                    {listNews && listNews.map((list, index) =>
                        <tr key={index}>
                            <th>{list.id}</th>
                            <td>{list.name}</td>
                            <td style={{ width : "20%"}}>
                                {list.new_catalog_relation.map((cata, i)=>
                                    <span key={i}>{cata.name}</span>
                                )}
                            </td>
                            <td style={{ width : "15%"}}>{list.created_at}</td>
                            <td style={{ width : "15%"}}>
                                <img src={list.image} alt="" style={{ "width": "100%"}}/>
                            </td>
                            <td style={{ width : "20%"}}>{list.status_name}</td>
                            <td >
                                <Link to={`/main/news/edit/${list.id}`}>
                                    <i className="mdi mdi-pencil font-size-18"></i>
                                </Link>
                                <Link  onClick={() => {handleShow();  setIdUserCurr(list.id); setNameUserCurr(list.name)}} to="#" className="text-danger">
                                    <i className="mdi mdi-delete font-size-18"></i>
                                </Link>
                            </td>
                        </tr>
                    )}
                </table>
                {total <= 10 ? '' :
                    <Pagination
                        onChange={(value) => setcurrentPage(value)}
                        total={total}
                        current={currentPage}
                        showSizeChanger= {false}
                    />
                }
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Xoá tin tức</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc chắn muốn xoá tin tức <span style={{'color':'blue'}}>{nameUserCurr}</span> không ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Thoát
                    </Button>
                    <Button variant="primary" onClick={() => {
                        handleDelete(idUserCurr);
                        handleClose();
                    }}>
                        Xác nhận
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default News
